.bucketAddComponent {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.25) ;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 12px;
    margin: 10px;
    img{
        height: 200px;
    }
    .title1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }
    .panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .section1 {
            display: flex;
            align-items: center;
            
            .section1Title1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 45px;
                color: #000000;
            }
            .section1Title2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .bucketAddComponent {
        .title1 {
            font-size: 18px;
        }
        .panel {
            .section1 {
                .section1Title1 {
                    font-size: 25px;
                }
                .section1Title2 {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .bucketAddComponent {
        img{
            height:110px;
        }
        .title1 {
            font-size: 15px;
        }
        .panel {
            .section1 {
                .section1Title1 {
                    font-size: 16px;
                }
                .section1Title2 {
                    font-size: 13px;
                }
            }
            .adhoc-button{
                padding:5px 5px !important;
                font-weight: 400px !important;
            }
        }
    }
}

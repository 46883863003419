.bucketBoxCountComponent {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.25) ;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 15px;
    text-align: left;
    .bi-plus-circle, .bi-dash-circle{
        font-size: 30px;   
    }
    .close{
        font-size: 30px;
        line-height: 30px;
        color: black;
    }
    .product-img{
        width: 150px;
        height: 150px;
    }
    .panel {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 760px;

        .section1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .section1Title {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 45px;
                color: #000000;
            }
        }

        .section2 {
            display: flex;
            flex-direction: column;
            span {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
            }
        }
        .section3 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .section3_1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                div{
                    height: 28px;
                    width: 28px;
                    border-radius: 100%;
                    background-color: #FFC700;
                    display: flex;
                    align-items: center;
                }
                .section3_1_title {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 45px;
                    color: #000000;
                }
            }

            .section3_2 {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 14px;

                .title1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 45px;
                    color: #000000;
                }
                .title2 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 30px;
                    color: #000000;
                }
            }
        }
        
    }
}

@media only screen and (max-width: 991px) {
    .bucketBoxCountComponent {
        .product-img{
            width: 100px;
            height: 100px;
        }
        .panel {
            width: unset;
            .section1 {
                .section1Title {
                    font-size: 23px;
                }
            }
            .section2 {
                span {
                    font-size: 18px;
                }
            }
            .section3 {
                .section3_1 {
                    .section3_1_title {
                        font-size: 23px;
                    }
                }
                .section3_2 {
                    .title1 {
                        font-size: 23px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .bucketBoxCountComponent {
        .product-img{
            width: 75px;
            height: 75px;
        }
        .panel {
            width: unset;
            .section1 {
                .section1Title {
                    font-size: 20px;
                }
            }
            .section2 {
                span {
                    font-size: 15px;
                }
            }
            .section3 {
                .section3_1 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    .section3_1_title {
                        font-size: 20px;
                    }
                }
                .section3_2 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    .title1 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
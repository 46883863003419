.card-container{ 
    cursor: pointer;
    position: relative;
    box-shadow: 0px 3px 4px 0 rgba(0,0,0,0.4), 3px 0px 4px 0 rgba(0,0,0,0.4) ;
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 2rem;
    .card-top {
        width: 100%;
        position: relative;
        .card-back {
            max-width: 100%;
            width: 100%;
            -webkit-clip-path: polygon(0% 0%, 0% 60%,100% 100%, 100% 0%);
            clip-path: polygon(0% 0%, 0% 60%,100% 100%, 100% 0%);
            border-radius: 20px;
        }
        .percent{
            position: absolute;
            background: #FFB800;
            border-radius: 20px;
            line-height: 37px;
            left:0;
            top:12px;
            width: 74px;
            height: 37px;
        }
        .title{
            position: absolute;
            line-height: 30px;
            left:0;
            bottom:0px;
            font-size: 30px;
            font-weight: bold;
        }
        
        margin-bottom: 1rem;
    }

    .card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        .rate{
            font-size: 30px;
            font-weight: bold;
        }
    }
}
@media screen and (max-width: 1440px) {
    .card-container {
        .percent{
            line-height: 25px !important;
            left:0;
            top:12px;
            width: 50px !important;
            height: 25px !important;
        }
        .title{
            left:0px !important;
            bottom:-10px !important;
            font-size: 25px !important;
        }

        .card-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 17px;
            .rate{
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
  }

@media screen and (max-width: 992px) {
    .card-container {
        .card-top {
            .percent{
                line-height: 30px !important;
                left:0;
                top:12px;
                width: 60px !important;
                height: 30px !important;
            }
            .title{
                font-size: 25px !important;
            }
            margin-bottom: 1rem;
        }    
        }
  }
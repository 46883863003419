.productBreadcrumbsSection {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
    }
}
@media screen and (max-width: 767px) {
    .productBreadcrumbsSection {
      display: none;
    }
  }
.specialMentionSection {
    padding: 20px;
    margin-top: 40px;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.25) ;
    border-radius: 20px;
    textarea {
        background: #FFFFFF;
        width: 100%;
        // margin: 10px;
        border: none;
        outline: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 27px;
    }
}

@media only screen and (max-width: 991px) {
    .specialMentionSection {
        textarea {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .specialMentionSection {
        textarea {
            font-size: 15px;
        }
    }
}
.orderCompleteSection {
    padding-top: 50px;
    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 52px;
        color: #000000;
        
    }

    .orderCompleteSectionPanel {
        padding: 10px 0px;
    }
}

@media only screen and (max-width: 991px) {
    .orderCompleteSection {
        padding-top: 30px;
        .title {
            font-size: 25px;
        }
        .orderCompleteSectionPanel {
            padding: 7px 0px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .orderCompleteSection {
        padding-top: 20px;
        .title {
            font-size: 23px;
        }
        .orderCompleteSectionPanel {
            padding: 5px 0px;
        }
    }
}
.tipBtnComponent {
    button {
        background: #FFFFFF;
        box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
        border-radius: 40px;
        padding: 5px 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border: none;
        outline: none;

        .title1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 45px;
            color: #000000;
        }
        .title2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
        }
    }

    .btnBackground {
        background-color: #FFB800;
    }
}

@media only screen and (max-width: 991px) {
    .tipBtnComponent {
        button {
            margin: auto;
            .title1 {
                font-size: 18px;
            }
            .title2 {
                font-size: 17px;
            }
        }
    
        .btnBackground {
            background-color: #FFB800;
        }
    }
}

@media only screen and (max-width: 500px) {
    .tipBtnComponent {
        button {
            padding: 7px 20px;

            .title1 {
                font-size: 17px;
            }
            .title2 {
                font-size: 15px;
            }
        }
    
        .btnBackground {
            background-color: #FFB800;
        }
    }
}
.productCategoryItemComponent {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.25) ;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    position: relative;
    width: 94%;
    margin:3%;
    height: 94%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    img{
        width: 100% !important;
        height: 100%;
    }
    .product-image{
        position: absolute;
        width: 50% !important;
        height: 50% !important;
        left: 25%;
        top: 10%;
    }
    .title {
        font-size: 25px;
        line-height: 38px;
        color: #000000;
    }
}
@media screen and (max-width: 992px) {
    .productCategoryItemComponent{
        .title {
        font-size: 20px !important;
        line-height: 30px !important;     
        }        
    }
}